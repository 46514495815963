<template>
  <d-remote-monitoring-templates
    @on-select-template="onSelectTemplateHandler"
  />
</template>

<script>

export default {
  name: 'RemoteMonitoringTemplates',
  page: {
    title: 'CRM Doctis - Шаблоны ДМ',
  },
  methods: {
    onSelectTemplateHandler(id) {
      this.$router.push(`/remote-monitoring/templates/view/${id}/`);
    },
  },
};
</script>
